<template>
  <header class="header dark-bg" :class="{ expanded: state.isBurgerOpened }">
    <div class="wrapper">
      <div class="header__logo">
        <nuxt-link class="header__logo-link" to="/" @click="toggleMobileMenu">
          <header-logo-light />
        </nuxt-link>
      </div>
      <div class="header__mobile-actions">
        <button v-if="state.isBurgerOpened" class="header__mobile-close" @click="toggleMobileMenu" />
        <button v-else class="header__mobile-burger" @click="toggleMobileMenu" />
      </div>
      <div v-if="state.isBurgerOpened" class="header__mobile-dropdown dark-bg">
        <div class="header__mobile-navigation">
          <mobile-nested-link
            class="header__mobile-link"
            :links="[
              { to: '/autoanswers', label: $t('links.autobot') },
              { to: '/telegram-bot', label: $t('links.telegramBot') },
              { to: '/questions', label: $t('links.questions') },
              { to: '/ai-review-response-generator', label: $t('links.aiReviewResponeGenerator') },
              { to: '/analytics', label: $t('links.analyser') },
              { to: '/crm', label: $t('links.crm') },
            ]"
            :label="$t('links.services')"
            @reset-overflow="toggleMobileMenu"
          />
          <nuxt-link class="header__mobile-link" to="/prices" @click="toggleMobileMenu">
            {{ $t('links.advantages') }}
          </nuxt-link>
          <nuxt-link class="header__mobile-link" to="/news" @click="toggleMobileMenu">
            {{ $t('links.news') }}
          </nuxt-link>
          <nuxt-link class="header__mobile-link" to="/blog" @click="toggleMobileMenu">
            {{ $t('links.blog') }}
          </nuxt-link>
          <nuxt-link class="header__mobile-link" :to="{ path: '/', hash: '#contacts' }" @click="onClickContacts">
            {{ $t('links.contacts') }}
          </nuxt-link>
          <nuxt-link class="header__mobile-link" :to="{ path: '/signin', label: $t('enter') }" @click="toggleMobileMenu">
            {{ $t('enter') }}
          </nuxt-link>
        </div>
        <div class="header__mobile-buttons">
          <button type="button" class="header__mobile-help" @click="helpButtonClick">
            <a href="tel:+79522170605" class="header__telephone">
              {{ $t('links.help') }}
            </a>
          </button>
          <div class="header__mobile-actions">
            <a class="header__mobile-button telegram" href="https://t.me/Salesarea" target="_blank" />
            <a
              class="header__mobile-button whatsapp"
              target="_blank"
              href="https://api.whatsapp.com/send/?phone=79522170605&text=Добрый+день%21+Помогите+разобраться.&type=phone_number&app_absent=0"
            />
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import MobileNestedLink from '~/components/layout/mobile-nested-link.vue';
import HeaderLogoLight from '~/components/svg/header-logo-light.vue';

const state = reactive({
  isBurgerOpened: false,
});
const toggleMobileMenu = () => {
  state.isBurgerOpened = !state.isBurgerOpened;
  document.body.style.overflow = state.isBurgerOpened ? 'hidden' : 'visible';
};

const onClickContacts = () => {
  toggleMobileMenu();
  setTimeout(() => {
    const contactsSection = document.querySelector('#contacts');
    contactsSection.scrollIntoView({ behavior: 'smooth' });
  }, 100);
};
</script>

<style scoped lang="scss">
.header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  padding: 12px 0;

  .wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__logo {
    display: flex;
    align-items: center;
  }

  &__telephone {
    color: white;
  }

  &__mobile {
    &-link {
      font-weight: 900;
      color: white;
    }

    &-actions {
      display: none;
    }

    &-burger {
      width: 28px;
      height: 18px;
      background: url('/svg/header-burger.svg') no-repeat center center;
      background-size: contain;
    }

    &-close {
      width: 30px;
      height: 30px;
      background: url('/svg/header-close.svg') no-repeat center center;
      background-size: contain;
    }

    &-dropdown {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 32px 0;
      grid-column-start: 1;
      grid-column-end: 3;
    }

    &-navigation {
      display: flex;
      flex-direction: column;
      gap: 32px;
    }

    &-help {
      color: white;
      font-family: 'Raleway', sans-serif;
      font-weight: 700;
    }
  }
}

.telegram {
  background: url('/svg/button-telegram.svg');
  background-size: cover;
}
.whatsapp {
  background: url('/svg/button-whatsapp.svg');
  background-size: cover;
}

@media (max-width: 910px) {
  .header {
    padding: 48px 36px 24px;

    &.expanded {
      height: 100%;
      position: fixed;
      max-height: 100%;
      overflow-y: auto;

      .wrapper {
        display: grid;
        height: 100%;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: max-content 1fr;
      }

      .header__logo {
        width: 170px;
        justify-self: flex-start;
      }

      .header__mobile-actions {
        justify-self: flex-end;
      }
    }

    &__logo {
      display: flex;
      height: 42px;

      &-link {
        display: block;
        width: 100%;
        height: 100%;

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }

    &__buttons {
      display: none;
    }

    &__mobile {
      &-link {
        font-size: 28px;
      }

      &-buttons {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        margin: auto auto 0;
      }

      &-actions {
        display: flex;
        align-items: center;
        gap: 16px;
      }

      &-button {
        display: flex;
        width: 60px;
        height: 60px;
      }

      &-help {
        font-size: var(--mobile-smallest-text-sise);
      }
    }
  }
}
</style>
